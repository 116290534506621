<template>
    <div class="statistmain" :style="{zoom:wsw/19.20 + '%'}">
        <div class="stathed">
            <img class="bg" alt="" src="../../img/header.png" />
            <div class="textbox">
                <p>{{ username }}</p>
                <p>MES系统生产管理看板</p>
            </div>
            <div class="tabbox">
                <div class="tab " @click="goto('/statistics')">首页</div>
                <div class="tab" @click="goto('/home')">设备看板</div>
                <div class="tab" @click="goto('/orderOverview')">订单看板</div>
                <div class="tab avtive" @click="goto('/personnelOverview')">人员看板</div>
            </div>
            <div class=" sy" @click="offall">
                进入主控台
            </div>
            <div class="sjbox">
                <p>{{ dayjs(date).format('HH:mm') }}</p>
                <p>{{ dayjs(date).format('YYYY-MM-DD') }}</p>
            </div>
        </div>
        <div class="stebox ">
            <div class="steboxhed">
                人员信息
            </div>
            <div class="setmain">
                <div class="settabbox">
                    <div class="settab " :class="xstype == 'all' ? 'active' : ''" @click="tan('all')">全部
                        <div class="xian"></div>

                    </div>
                    <div class="settab " :class="xstype == item.group_id ? 'active' : ''" @click="tan(item.group_id)"
                        v-for="item in list" :key="item.group_id">{{ item.name }}({{ item.allCount }})
                        <div class="xian"></div>
                    </div>
                </div>
                <div class="renul">
                    <div class="renli" v-for="item in xslist" :key="item.id">
                        <div class="henbox">
                            <img v-if="item.tasks.length == 0" src="../../img/reny.png" alt="">
                            <img v-else src="../../img/reny3.png" alt="">
                            <div class="namebox">
                                <p>{{ item.name }}</p>
                                <p>部门：{{ item.bm }}</p>
                            </div>
                            <div class="numbox">
                                <p>{{ item.tasks.length }}</p>
                                <p>待完成</p>
                            </div>
                        </div>
                        <div v-if="item.tasks.length > 0">
                            <div class="ptext">
                                当前工序：{{
                                    item.tasks[0].type == 1 ? item.tasks[0].order_name + '-' + item.tasks[0].order_item_name +
                                    '-' + item.tasks[0].order_process_name : '直接分配'
                                }}
                            </div>
                            <div class="ptext"> 分配时间：{{ item.tasks[0].created_at }}
                            </div>
                            <div class="ptext">当前进度：{{ item.tasks[0].complete_num + '/' + item.tasks[0].allot_num }}

                                <span class="jd" style="float: right;">
                                    {{ item.tasks[0].allot_num > 0 ? ((item.tasks[0].complete_num / item.tasks[0].allot_num
                                    ) * 100 + '%') : "" }}
                                </span>
                            </div>
                            <!-- <div class="jdtext">{{ ( item.tasks[0].complete_num / item.tasks[0].allot_num )*100 + '%'  }}</div> -->
                            <div class="jdt">
                                <div class="jd"
                                    :style="{ width: (item.tasks[0].complete_num / item.tasks[0].allot_num) * 100 + '%' }">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="ptext">
                                当前工序：无
                            </div>
                            <div class="ptext"> 分配时间：无
                            </div>
                            <div class="ptext">当前进度：无
                            </div>
                            <!-- <div class="jdtext">无</div> -->
                            <div class="jdt">
                                <div class="jd" :style="{ width: 0 + '%' }"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
// import { mapGetters } from "vuex";
// import echarts from "echarts";

require("echarts/theme/macarons"); // echarts theme

// import { getMonth } from "@/utlis/date";
// import { bind, orderCountList, staffTaskList } from "@/service/api";

import Cookies from 'js-cookie';
import { getPersonnelOverview } from "@/service/statistics";

export default {
    name: "Home",
    components: {
        // allhed
    },
    data() {
        return {
            wsw:window.screen.width,
            staffdata: null,
            ddData: null,
            sj: new Date(),
            boxSize: 0,  //0最大 5最小
            Screen: false,
            isphone: false,
            count: 0,
            isall: true,
            username: Cookies.get('userNane'),
            logo: Cookies.get('logo'),
            ws: null,
            date: new Date().valueOf(),
            chart: null,
            resizeHandler: null,
            radio1: "今日",
            datevalue1: "",
            swlistdata: [],
            chartList: [
                {
                    list: [
                        { value: 235, name: "开机" },
                        { value: 274, name: "停机" }
                    ]
                },
                {
                    list: [
                        { value: 50, name: "开机" },
                        { value: 80, name: "停机" }
                    ]
                },
                {
                    list: [
                        { value: 90, name: "开机" },
                        { value: 80, name: "停机" }
                    ]
                }
            ],
            list: [],
            onlinenum: "",
            offnum: "",
            ontime: 0,
            //断开链接弹窗
            isws: true,
            xslist: [],
            xstype: 'all'
        };
    },
    computed: {



    },
    watch: {

    },

    methods: {
        tan(type) {
            this.xstype = type;
            this.xsdata();
        },
        goto(url) {
            this.$router.push(url);
        },
        offall() {
            this.$router.push('/sc/companyOrder');
        },
        getPersonnelOverview() {
            getPersonnelOverview().then(res => {
                this.list = res.data;
                let zong = 0;
                this.list.forEach(item => {
                    zong = zong + item.staffList.length;
                });
                this.count = zong;

                this.xsdata();
            });
        },
        // 处理数组 
        xsdata() {

            if (this.xstype === 'all') {
                // 全部
                let xslist = [];
                this.list.forEach(item => {
                    item.staffList.forEach(item2 => {
                        item2.bm = item.name;
                    });
                    xslist = xslist.concat(item.staffList);

                    // if(item.tasks.length > 0){
                    //     console.log(item);
                    // }
                });
                this.xslist = xslist;
                console.log(this.xslist);
            } else {
                let xslist = [];
                this.list.forEach(item => {
                    if (item.group_id === this.xstype) {
                        item.staffList.forEach(item2 => {
                            item2.bm = item.name;
                        });
                        xslist = item.staffList;
                    }


                });
                this.xslist = xslist;
                console.log(this.xslist);
            }
        }
    },
    created() {
        this.getPersonnelOverview();


    },
    destroyed() {

    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    mounted() {
        const _this = this;

        this.timer = setInterval(() => {
            _this.date = new Date(); // 修改数据date
            this.getPersonnelOverview();
        }, 600000);
        // this.all();

    }
};
</script>
<style>
.el-carousel__indicator.is-active button {
    opacity: 1;
    background-color: #53FFFF !important;
}

body,
html {
    height: 100%;
}

#app {
    height: 100%;
}

body {  background: #04274D;}
</style>
<style lang="scss" scoped>
.v-modal {
    z-index: 1 !important;
}

.statistmain {
    // zoom: 80%;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background: #04274D;
    position: relative;

    .stathed {
        width: 100%;
        height: 120px;
        position: relative;

        .bg {
            width: 100%;
            height: 200px;
        }

        .textbox {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;
            height: 90px;

            p {
                margin: 0;

                &:nth-child(1) {
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 70px;
                }

                &:nth-child(2) {
                    color: #FFFFFF;
                    line-height: 14px;
                    font-size: 14px;
                }
            }
        }

        .tabbox {
            position: absolute;
            top: 44px;
            left: 40px;

            .tab {
                width: 105px;
                height: 40px;
                background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
                box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                text-align: center;
                line-height: 40px;
                color: #ACD8FF;
                font-size: 16px;
                float: left;
                margin-right: 32px;
                cursor: pointer;
            }

            .avtive {
                color: #FFFFFF;
                background: linear-gradient(180deg, #088BFF 0%, rgba(8, 139, 255, 0) 100%);
            }
        }

        .sy {
            cursor: pointer;
            width: 120px;
            height: 40px;
            background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
            box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 40px;
            color: #ACD8FF;
            font-size: 16px;

            margin-right: 32px;
            position: absolute;
            top: 44px;
            right: 140px;
        }

        .sjbox {
            position: absolute;
            top: 44px;
            right: 40px;

            p {
                margin: 0;

                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: #EFF7FF
                }

                &:nth-child(2) {
                    font-size: 18px;
                    color: #EFF7FF;
                    line-height: 28px;
                }
            }
        }
    }

    .stebox {
        width: calc(100% - 80px);
        margin: 0 auto;
        height: 920px;
        background: rgba(12, 109, 212, 0.2);
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        border: 1px solid;

        border-image: linear-gradient(225deg, rgba(51, 204, 204, 0), rgba(51, 204, 204, 0.2), rgba(51, 204, 204, 0)) 1 1;

        // position: absolute;

        .steboxhed {
            width: 513px;
            height: 48px;
            background-image: url(../../img/shed.png);
            font-weight: 600;
            font-size: 18px;
            line-height: 48px;
            color: #ACD8FF;
            padding-left: 55px;
            box-sizing: border-box;
        }

        .settabbox {
            width: 100%;
            height: 48px;
            line-height: 48px;
            overflow: hidden;
            padding: 0 40px;
            box-sizing: border-box;

            .settab {
                font-size: 18px;
                font-weight: 400;
                color: #ACD8FF;
                float: left;
                margin-right: 40px;
                position: relative;
                cursor: pointer;

                .xian {
                    content: '';
                    width: 32px;
                    height: 4px;
                    background: #53FFFF;
                    border-radius: 12px 12px 12px 12px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0px;
                    display: none;

                }
            }



            .active {
                color: #53FFFF;

                .xian {
                    display: block;
                }

            }
        }

        .renul {
            width: 100%;
            overflow: hidden;

            .renli {
                width: 288px;
                height: 152px;
                background: rgba(21, 154, 255, 0.2);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                float: left;
                margin-left: 16px;
                margin-top: 12px;
                box-sizing: border-box;

                .henbox {
                    width: 100%;
                    height: 60px;
                    position: relative;

                    img {
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 12px;
                        left: 16px;
                    }

                    .namebox {
                        position: absolute;
                        top: 12px;
                        left: 72px;
                        height: 48px;

                        p {
                            margin: 0;

                            &:nth-child(1) {
                                font-size: 16px;
                                font-weight: 500;
                                color: #CEE7FF;
                                line-height: 24px;
                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                font-weight: 400;
                                color: #CEE7FF;
                                line-height: 24px;
                            }
                        }
                    }

                    .numbox {
                        position: absolute;
                        top: 12px;
                        right: 16px;
                        height: 48px;

                        p {
                            margin: 0;

                            &:nth-child(1) {
                                font-size: 24px;
                                font-weight: bold;
                                color: #5AE460;
                                line-height: 24px;
                                text-align: center;
                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                font-weight: 400;
                                color: #EFF7FF;
                                line-height: 24px;
                            }
                        }
                    }
                }

                .ptext {
                    font-weight: 400;
                    font-size: 14px;
                    color: #CEE7FF;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 16px;
                    line-height: 24px;
                }

                .jdtext {

                    float: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #CEE7FF;
                }

                .jdt {

                    width: calc(100% - 32px);
                    margin: 0 auto;
                    margin-top: 5px;
                    background: #1E78CE;
                    height: 8px;
                    border-radius: 49px 49px 49px 49px;
                    position: relative;

                    .jd {
                        position: absolute;
                        background: #53FFFF;
                        left: 0;
                        border-radius: 49px 49px 49px 49px;
                        height: 8px;
                    }
                }
            }
        }

    }


}</style>
